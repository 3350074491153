/* eslint-disable react/function-component-definition */
import { User as AuthUser, useAuth0, LogoutOptions } from "@auth0/auth0-react";
import { FC, useEffect, useMemo } from "react";
import {
  Navigate, Outlet, RouteProps, useLocation, useNavigate,
} from "react-router-dom";

import useMonitorAndRedirect from "hooks/useRedirect";
import useUserSettings from "services/shooks/settings";
import { UserScope } from "types/api";
import { onIdentifyUserLoginEvent } from "utils/userActivitiesTracker/logRocket";

import Spinner from "./common/Spinner";
import { userStorage } from "utils/auth";
export let auth0ClientUser = {
  logout: ((o?: any) => { }) as (options?: LogoutOptions | undefined) => void
}

const enum ScopeState {
  isLoading = "loading",
  isOnboarding = "onboarding",
  canRead = "canRead",
  cannotRead = "cannotRead",
}

const ForbiddenRoutes = [
  "whitespace",
];

const ProtectedRoute: FC<RouteProps> = () => {
  const { reroute } = useMonitorAndRedirect();
  const { state: { scope, isTrial } } = useUserSettings();
  const navigate = useNavigate()

  const {
    isAuthenticated,
    logout,
    user,
    isLoading,
    error,
  } = useAuth0<User & AuthUser>();

  auth0ClientUser.logout = logout
  const { pathname } = useLocation();
  const pathKey = pathname.split("/")[1] as keyof UserScope;

  const scopeState = useMemo<ScopeState>(() => {
    if (!scope) return ScopeState.isLoading;

    const isForbidden = ForbiddenRoutes.includes(pathKey as string);
    const canOnboard = Object.keys(scope.onboarding).includes("onboarding");
    const isHome = pathKey as string === "";
    const isOnboarding = pathKey as string === "onboarding";
    if ((!canOnboard && isOnboarding) || isForbidden) return ScopeState.cannotRead;
    if (canOnboard) return ScopeState.isOnboarding;

    if (isHome) return ScopeState.canRead;

    // might require some changes
    if (Object.keys(scope).includes(pathKey)) return ScopeState.canRead;
    return ScopeState.canRead;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, scope, isLoading, isAuthenticated, user]);


  // @todo: this should be moved to app initialization context
  useEffect(() => {
    if (isAuthenticated && user) {
      // @note: to track the user session in logRocket
      onIdentifyUserLoginEvent(user);
      // @note: to check if the user has completed onboarding
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(user)]);


  useEffect(() => {
    const _error = error as any;
    if (_error && _error?.error_description) {
      const errorMessage = _error.error_description || "An error occurred, please try again later";
      navigate("/error", { state: { error: { message: errorMessage } } });
    }
  }, [error]);

  useEffect(() => {
    if (isAuthenticated && user) reroute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && isTrial && !pathname.includes('settings/integrations')) {
      navigate('/settings/integrations')
    }
  }, [isAuthenticated, isTrial, pathname.includes('settings/integrations')])


  if (isLoading || (isAuthenticated && scopeState === ScopeState.isLoading)) return <Spinner />;
  if (!isAuthenticated) return <Navigate to="/login" />;
  // if (scopeState === ScopeState.cannotRead) return <Navigate to="" />;
  if (!isTrial && pathKey as string !== "onboarding" && scopeState === ScopeState.isOnboarding) return <Navigate to="/onboarding" />;
  if (!isAuthenticated && error) {
    const errorMessage = typeof error === "string" ? error : error?.message || "An error occurred, please try again later";
    return <Navigate to="/error" state={{ error: errorMessage }} />;
  }
  return <Outlet />;
};

export default ProtectedRoute;
