import { Stack } from "@mui/material";

import { useCallback, useEffect, useRef } from "react";

import { useIsOverflowing } from "hooks";
import { FilterGroupsOpenStateMap } from "pages/Territory/utils";
import useEmployeeHierarchy from "services/shooks/EmployeeHierarchy";
import {
  DynamicFilterT, FilterTypesEnum, FiltersKV, FiltersV, MinMaxFilterTypes, RangeFilterT,
} from "types/api";

import { AccountPriorityEnums, PipelineSignalEnums, TerritoryFiltersEnums } from "types/api/territoryFilters";

import PipelineSignalFilter from "./AccountFit";
import AccountOwner from "./AccountOwner";
import { FiltersGroupBox } from "./common";
import InputFilter from "./DynamicFilterComps/InputFilter";
import NumberFilter from "./DynamicFilterComps/NumberFilter";
import SimpleSelect from "./DynamicFilterComps/SimpleSelect";
import FiltersHead from "./FiltersHead";
import MinMaxInput from "./MinMaxInput";
import BasicTabs from "../Tabs";
import { omitLimitAndOffset, spaceAndCapitalize } from "../utils";
import useTerritoryFilters from "services/shooks/territoryFilters";
import PriorityFilter from "./Priority";
import SimilarWinsFilter from "./CustomFilters/SimilarWins";
import { useAuth0 } from "@auth0/auth0-react";
import { useSettings } from "services/shooks";

interface FiltersProps {
  onHideFilters: () => void;
  onResetFilters: () => void;
  filtersMetaData: DynamicFilterT;
  filterGroupsOpenMap: FilterGroupsOpenStateMap;
  onToggleFilterGroupOpenState: (
    groupKey: keyof FilterGroupsOpenStateMap | string,
    isOpen?: boolean
  ) => void;
}

const NON_DISPLAYED_FILTER_KEYS = ["accountName"];

function DynamicFilters(props: FiltersProps) {
  const {
    onHideFilters, onResetFilters, filtersMetaData, filterGroupsOpenMap,
    onToggleFilterGroupOpenState,
  } = props;
  const { user } = useAuth0<User>()
  const filtersContainerRef = useRef(null);
  const isFiltersOverflowing = useIsOverflowing(filtersContainerRef, "height");
  const { isInitialized, actions: employeeHierarchyActions } = useEmployeeHierarchy();
  const filtersValues = useTerritoryFilters(({ state }) => state.filters);
  const hasEmployeeHierarchy = useSettings((state) => Boolean(state.state?.employeeHierarchy?.subordinates?.length));

  const onToggleFilterBox = (groupKey: keyof FilterGroupsOpenStateMap | string) => (isOpen?: boolean) => {
    onToggleFilterGroupOpenState(groupKey, !isOpen);
  };

  useEffect(() => {
    if (!isInitialized && hasEmployeeHierarchy)
      employeeHierarchyActions.initialize({ userId: user?.SFUserId! })
  }, []);

  const getActiveFilterCount = (filters: FiltersV): number => {
    if (!filters) return 0;
    let count = 0;
    Object.values(filters).forEach((filter) => {
      if (filter && filter.value) {
        count += Array.isArray(filter.value) ? filter.value.length : 1;
      }
    });
    return count;
  };

  const renderFilter = (
    objectType: string,
    section: string,
    key: FilterTypesEnum,
    value: FiltersV,
  ): JSX.Element | null => {
    // @note: temp, once its supported from the backend then the following line should be removed;
    if ((key as string) === 'opportunityCandidates') return null;

    switch (value.type) {
      case FilterTypesEnum.String:
        if ((key as string) === 'similarWins') {
          return (
            <SimilarWinsFilter
              valuePath={`${objectType}.${section}.${key}.value`}
              label={spaceAndCapitalize(key)}
              hasActiveFilters
              values={value.value as string[] ?? []}
              options={value.options as string[] ?? []}
            />
          )
        }
        return (
          <InputFilter
            valuePath={`${objectType}.${section}.${key}.value`}
            label={spaceAndCapitalize(key)}
            hasActiveFilters
            values={value.value as string[] ?? []}
            options={value.options as string[] ?? []}
          />
        );
      case FilterTypesEnum.Range:
        return (
          <MinMaxInput
            valuePath={`${objectType}.${section}.${key}.value`}
            label={spaceAndCapitalize(key)}
            type={MinMaxFilterTypes.Number}
            hasActiveFilters
            range={(value as RangeFilterT).options}
            value={(value as RangeFilterT).value}
          />
        );
      case FilterTypesEnum.Choice:
        return (
          <SimpleSelect
            valuePath={`${objectType}.${section}.${key}.value`}
            label={spaceAndCapitalize(key)}
            value={value.value as string}
            options={(value.options as string[])}
          />
        );
      case FilterTypesEnum.Enum:
        switch (key) {
          case 'suitability' as typeof key:
            return (
              <PipelineSignalFilter
                valuePath={`${objectType}.${section}.${key}.value`}
                value={value.value as PipelineSignalEnums[] ?? []}
                title={spaceAndCapitalize(key)}
              />
            );
          case 'priority' as typeof key:
            return (
              <PriorityFilter
                valuePath={`${objectType}.${section}.${key}.value`}
                value={value.value as AccountPriorityEnums[] ?? []}
                title={spaceAndCapitalize(key)}
              />
            )
          default:
            return null;
        }
      case FilterTypesEnum.DateRange:
        return (
          <MinMaxInput
            valuePath={`${objectType}.${section}.${key}.value`}
            label={spaceAndCapitalize(key)}
            type={MinMaxFilterTypes.Date}
            hasActiveFilters
            range={(value as RangeFilterT).options}
            value={(value as RangeFilterT).value}
          />
        );
      case FilterTypesEnum.Hierarchy:
        return (hasEmployeeHierarchy ? (
          <AccountOwner
            valuePath={`${objectType}.${section}.${key}.value`}
            values={value.value as string[] ?? []}
            isFilterBoxOpen={!filterGroupsOpenMap[TerritoryFiltersEnums.accountOwners]}
            onToggleFilterBox={onToggleFilterBox(TerritoryFiltersEnums.accountOwners)}
          />
        )
          : (
            <>
            </>
          )
        );
      case FilterTypesEnum.Number:
        return (
          <NumberFilter
            valuePath={`${objectType}.${section}.${key}.value`}
            label={spaceAndCapitalize(key)}
            hasActiveFilters
            value={value.value as number | undefined}
          />
        );
      default:
        return <> </>;
      // type dropdown ?
      // <StringFilter
      //   valuePath={`${objectType}.${section}.${key}.value`}
      //   values={value.value as string[] ?? []}
      //   label={spaceAndCapitalize(key)}
      //   options={value.options as string[] ?? []}
      //   isLoadingAutoCompleteData={false}
      //   onRemoveSelectedOption={onRemoveSelectedOption}
      //   onOptionSelected={onOptionSelected}
      // />
    }
  };
  const getFieldValue = useCallback((objectType: string, section: string, key: string) => {
    // @ts-ignore
    return filtersValues?.[objectType]?.[section]?.[key]?.value;
  }, [filtersValues]);

  const mapSectionFilters = (
    filters: FiltersV,
    objectType: string,
    section: string,
  ): JSX.Element => (
    <>
      {Object.entries(filters)
        .filter(([filterKey2, _]) => !NON_DISPLAYED_FILTER_KEYS
          .includes(filterKey2))
        .map(([filterKey2, filterValue2]) => {
          const valueFilter = {
            ...filterValue2,
            value: getFieldValue(objectType, section, filterKey2) || filterValue2.value,
          } as FiltersV;
          return (
            <div key={filterKey2} style={{ marginBottom: "7px" }}>
              {renderFilter(
                objectType,
                section,
                filterKey2 as FilterTypesEnum,
                valueFilter as FiltersV,
              )}
            </div>
          )
        })
      }
    </>
  );

  const getFiltersOrder = (obj: FiltersKV) => {
    const order = ["owner", "insights", "attributes"];
    const sortedObj: FiltersKV = {};

    order.forEach((key) => {
      if (key in obj) {
        sortedObj[key] = obj[key];
      }
    });
    Object.keys(obj).sort().forEach((key) => {
      if (!order.includes(key)) {
        sortedObj[key] = obj[key];
      }
    });

    return sortedObj;
  };

  const getObjectChildren = (objectType: string, filtersKV: FiltersKV): JSX.Element => (
    <>
      {Object.entries(getFiltersOrder(filtersKV)).map(([section, filters]) => (
        section === "owner"
          ? (mapSectionFilters(filters, objectType, section)
          ) : (
            <div
              key={section}
              style={{ marginBottom: "10px" }}
            >
              <FiltersGroupBox
                title={spaceAndCapitalize(section)}
                activeFilterCount={getActiveFilterCount(filters)}
                isOpen={!filterGroupsOpenMap[`${objectType}:${section}`]}
                onToggle={onToggleFilterBox(`${objectType}:${section}`)}
              >
                {mapSectionFilters(filters, objectType, section)}
              </FiltersGroupBox>
            </div>
          )
      ))}
    </>
  );

  return (
    <Stack
      direction="column"
      borderRight="1px solid #F5F5F5"
      className="fade-in"
      sx={{ width: "310px" }}
    >
      <FiltersHead
        //key={JSON.stringify(filtersValues)}
        onHideFilters={onHideFilters}
        onResetFilters={onResetFilters}
      />
      <Stack
        ref={filtersContainerRef}
        direction="column"
        p="10px"
        gap="10px"
        sx={{
          m: isFiltersOverflowing ? "10px 10px 0 0" : "0",
          pt: isFiltersOverflowing ? "0" : "10px",
          overflow: "auto",
          maxHeight: "95%",
          "&::has(&::-webkit-scrollbar)": {},
          "&::-webkit-scrollbar": { width: "6px", borderRadius: "100px" },
          "&::-webkit-scrollbar-track": { backgroundColor: "#F5F5F5", borderRadius: "100px" },
          "&::-webkit-scrollbar-thumb": { backgroundColor: "#BBC8D1", borderRadius: "100px" },
        }}
      >
        <BasicTabs
          tabs={omitLimitAndOffset(filtersMetaData).map(([objectType, value]) => ({
            label: objectType,
            children: getObjectChildren(objectType, value as unknown as FiltersKV),
          }))}
        />
      </Stack>
    </Stack>
  );
}

export default DynamicFilters;
