import { Box } from "@mui/material";

import { MouseEvent, useEffect, useMemo } from "react";

import { Table } from "components/common/Tables";
import { useSearchQuery } from "hooks";
import { isValidTabFilter } from "pages/Territory/components/utils";
import { listToSelectOptions } from "utils/helpers";

import useAccountOpportunities from "./actions";
import useOpportunitiesColumns from "./columns";

const stageFilterOptions = [
  { value: "all", label: "All" },
  { value: "open", label: "Open" },
  { value: "win", label: "Closed Won" },
  { value: "lose", label: "Closed Lost" },
];

// name, amount, stage, source, type, owner
export enum OpportunityColumnsEnum {
  name = "name",
  amount = "amount",
  stage = "stage",
  source = "source",
  type = "type",
  owner = "owner",
}

function Opportunities({
  onSalesforceLinkClick,
}: {
  onSalesforceLinkClick: (e: MouseEvent, id: string) => void;
}) {
  const {
    opportunities, filters, filtersMeta, onFilterChange, isLoading,
  } = useAccountOpportunities();
  const [searchQuery, updateSearchQuery] = useSearchQuery<{
    open_tab?: string;
    prefilter: typeof filters.stage;
    filtered_column?: string;
    ac_feed?: boolean;
  }>();
  const { prefilter, filtered_column: filteredColumn } = searchQuery;

  const columnMeta = useMemo(
    () => ({
      meta: {
        value: filtersMeta?.value || { min: 0, max: 0 },
        stageOptions: stageFilterOptions,
        sourceOptions: listToSelectOptions(filtersMeta?.opportunitySources, true),
        typeOptions: listToSelectOptions(filtersMeta?.opportunityTypes, true),
      },
      onFilterChange,
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }),
    [JSON.stringify(filtersMeta)],
  );
  // @ts-ignore this is a temporary hotfix as this is not yet in scope for now.
  const opportunitiesColumns = useOpportunitiesColumns({
    ...columnMeta,
    filters,
    onSalesforceLinkClick,
  });

  useEffect(() => {
    const validColumns = Object.values(OpportunityColumnsEnum);
    const currentFilteredColumn = filteredColumn as string;
    const validFilterCheck = isValidTabFilter(validColumns, currentFilteredColumn);

    if (!validFilterCheck) return updateSearchQuery({ prefilter: undefined, filtered_column: "" });
    onFilterChange({ ...filters, stage: prefilter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefilter]);

  useEffect(() => {
    if (filters.stage) updateSearchQuery({ prefilter: filters.stage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.stage]);

  return (
    <Box sx={{ overflow: "auto", border: "1px solid #E6E6E6", borderBottom: "2px solid #eee" }}>
      <Box>
        <Table
          data={opportunities}
          columns={opportunitiesColumns}
          loading={isLoading}
          stickyHeader
          showFiltersRow
        />
      </Box>
    </Box>
  );
}

export default Opportunities;
