import { SelectOption } from "@mui/base";
import { Box, Button, Checkbox, CircularProgress, MenuItem, Select, Stack, Typography } from "@mui/material";
import {
  FC,
  MouseEvent, useCallback, useEffect, useMemo, useState,
} from "react";

import { useFilters } from "react-table";

import { Head } from "components/AccountTables/Head";
import { Table } from "components/common/Tables";
import Tooltip from "components/Tooltip";
import { useSearchQuery } from "hooks";
import useToggle from "hooks/useToggle";
import AccountDetailsAPI from "services/accountDetails";
import useActivities from "services/shooks/activities";
import { PeoplePerAccountResponse } from "types/api/people";
import { Account } from "types/api";
import { timeAgo } from "utils/timeAgo";

import { makePeopleData } from "../accountDetails.utils";
import { TextArea, textIncludes } from "../Details.filters";
import FindAndSavePeopleModal from "./AddPeopleModal";
import { SendOutlined } from "@ant-design/icons";
import aiWorkersService from "services/aiworkers";
import { toast } from "react-toastify";
import { CheckBox, EmailOutlined, LinkedIn, PhoneInTalk } from "@mui/icons-material";
import { BadgeCheckMark, SalesforceIcon } from "assets/icons";
import { CheckboxWithDropdown } from "components/AccountTables";
import { ContactTypeEnum } from "pages/AIWorkers/workers/PeopleFinder/hooks";
import { LookUpDataSource } from "services/shooks/territoryFilters";
import useUserSettings from "services/shooks/settings";
import { onGoToExternalLink } from "components/Activity.components/utils";

// TODO: this should be imported from the data-model to be in sync across all the repos
// TODO: look into how to install the data-model in the webapp and see if there any concern
enum ActivityCategory {
  Marketing = 0,
  Sales = 1,
  Wins = 2,
  CustomerSuccess = 3,
  SDR = 4,
  Channel = 5,
  Executive = 6,
  RevOps = 7,
  "Services & Support" = 8,
  System = 9,
}

const fetchPeoplePerAccount = async (payload: Record<string, any>, signal?: AbortSignal) => {
  const { accountId, status } = payload;
  try {
    const options = signal ? { signal } : undefined
    const people = await AccountDetailsAPI.people({ accountId, status }, options);
    return people;
  } catch (error) {
    console.error(error);
  }
};

enum AccountPeopleTabFilter {
  All = "all",
  Contact = "contact",
  Lead = "lead",
}

const peopleStatusOptions = [
  { label: "All", value: AccountPeopleTabFilter.All },
  { label: "Contact", value: AccountPeopleTabFilter.Contact },
  { label: "Lead", value: AccountPeopleTabFilter.Lead },
];

const getSelectOptionFromValue = (value: string, options: SelectOption<string>[]) => options.find((option) => option.value === value);
interface PeopleTabProps {
  account?: Account
};

const PeopleTab: FC<PeopleTabProps> = (props) => {
  const { account } = props;
  const [{ prefilter: peopleStatus, accid: currentAccountId }, updateSearchQuery] = useSearchQuery<{
    prefilter?: string;
    accid?: string;
    ac_feed?: boolean;
  }>();
  const { instanceURL } = useUserSettings(({ state }) => state);
  const [people, setPeople] = useState<PeoplePerAccountResponse | []>([]);
  const { actions: activitiesActions } = useActivities();
  const [isLoading, , asyncToggleLoading] = useToggle(true);
  const [showFindPeopleModal, setShowFindPeopleModal] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const peopleData = useMemo(() => makePeopleData(people, selected, instanceURL), [people, selected]);
  const peopleNumberExcludingSaved = peopleData.filter((person: any) => person.isSaved).length
  const isAllSelected = peopleNumberExcludingSaved > 1 && (peopleNumberExcludingSaved === selected.length);
  const canSelectAny = peopleNumberExcludingSaved < (people as PeoplePerAccountResponse)?.items?.length
  const [contactType, setContactType] = useState<ContactTypeEnum>(ContactTypeEnum.Lead);
  const onContactSavedAsChange = (type: ContactTypeEnum) => {
    setContactType(type)
  }
  const [isSavingContacts, , asyncSavingToggle] = useToggle()

  const updatePeopleForAccount = async (
    accountId: string,
    filters: { status: string },
    signal?: AbortSignal,
  ) => {
    const people: PeoplePerAccountResponse = await asyncToggleLoading(
      fetchPeoplePerAccount({ accountId, status: filters.status }, signal),
    );
    setPeople(people || []);
    return people;
  };

  useEffect(() => {
    const lastAbortController = new AbortController();
    const { signal } = lastAbortController;
    if (!currentAccountId) return;

    updatePeopleForAccount(currentAccountId, { status: peopleStatus || "all" }, signal);
    return () => {
      lastAbortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccountId, peopleStatus]);


  const onLastSignalClicked = async (e: MouseEvent, personName: string) => {
    e.stopPropagation();
    if (currentAccountId) {
      activitiesActions.filterByPerson(personName);
      // we need the openTab too, might need other things.
      updateSearchQuery({ ac_feed: true });
    }
  };

  const onPeopleStatusChange = (status?: string) => {
    if (status) updateSearchQuery({ prefilter: status });
  };

  const onSaveContactToSalesforce = async (peopleIds: string[]) => {
    if (!(currentAccountId && peopleIds.length)) return;
    const { error } = await aiWorkersService.savePeopleToCRM({
      data: [{
        accountId: currentAccountId,
        peopleIds: peopleIds,
        // contactType
      }]
    })

    if (!error) {
      toast.success('Saved successfully')
      return true
    }
    else {
      toast.error(error || 'Contact not saved to salesforce')
      return false
    }
  };

  const onSelectAllChange = () => {
    if (isAllSelected) return setSelected([])
    const peopleIds = peopleData
      .filter((person: any) => !person.isSaved)
      .map((person: any) => person.id);

    return setSelected(peopleIds)
  }

  const onSelectToggle = (personId: string) => {
    setSelected((pervSelected) => {
      const isExist = pervSelected.find(pid => pid === personId)
      if (isExist) {
        return pervSelected.filter(pid => pid !== personId)
      }
      return [...pervSelected, personId]
    })
  };
  const onSaveSelection = useCallback(async () => {
    const isSaved = await asyncSavingToggle(onSaveContactToSalesforce(selected))
    if (isSaved) {
      toast.success(`${contactType} saved successfully`)
      onReset()
    }
  }, [selected])

  const onReset = () => {
    setSelected([])
    setContactType(ContactTypeEnum.Lead)
  }

  const productsColumns = [
    {
      Header: (
        <Head sx={{ width: "25px", display: "flex", justifyContent: "flex-start" }}>
          {canSelectAny && (
            <Checkbox
              onChange={onSelectAllChange}
              sx={{ padding: 0 }}
              onClick={(e) => e.stopPropagation()}
              checked={isAllSelected}
              disabled={canSelectAny}
            />
          )}
        </Head>
      ),
      disableSortBy: true,
      Footer: "id",
      accessor: "id",
      className: "cell-left collapse-column",
      style: { width: "25px", maxWidth: '25px', paddingRight: '0px' },
      borderLeft: "1px solid #E5E5E5",
      Cell: ({ row }: { row: any }) => (
        <Box key={row.original.id} sx={{ userSelect: 'none' }}>
          {!row.original.isSaved && (
            <Checkbox
              onChange={() => onSelectToggle(row.original.id)}
              sx={{ padding: 0 }}
              onClick={(e) => e.stopPropagation()}
              checked={row.original.isSelected}
            />
          )}
        </Box>
      ),
    },
    {
      Header: () => <Head>Name</Head>,
      Footer: "name",
      accessor: "name",
      className: "cell-left collapse-column",
      placeholder: "Search by name",
      // disableFilters: true,
      disableGroupBy: true,
      borderLeft: "1px solid #E5E5E5",
      Filter: TextArea,
      filter: textIncludes,
      style: {
        width: "230px",
        minWidth: "230px",
        maxWidth: "230px",
      },
      Cell: ({ row }: any) => <Box sx={{ width: "200px" }}>{row.original.name || "N/A"}</Box>,
    },
    {
      Header: () => <Head>Status</Head>,
      Footer: "status",
      accessor: "status",
      placeholder: "Search by status",
      className: "cell-left collapse-column",
      disableGroupBy: true,
      borderLeft: "1px solid #E5E5E5",
      filterType: "select",
      filterProps: {
        sx: {
          height: "30px",
          width: "100%",
        },
        options: peopleStatusOptions,
        // @ts-ignore
        value: getSelectOptionFromValue(peopleStatus || "", peopleStatusOptions),
        onChange: onPeopleStatusChange,
      },
      style: {
        width: "150px",
        minWidth: "150px",
        maxWidth: "150px",
      },
      Cell: ({ row }: any) => (
        <Box sx={{ textTransform: "capitalize", width: "120px" }}>
          {row.original.status || "N/A"}
        </Box>
      ),
    },
    {
      Header: () => <Head>Last Signal</Head>,
      Footer: "lastSignal",
      accessor: "lastSignal",
      borderLeft: "1px solid #E5E5E5",
      className: "cell-left collapse-column",
      disableGroupBy: true,
      disableFilters: true,
      style: {
        width: "150px",
        minWidth: "150px",
        maxWidth: "150px",
      },
      Cell: ({ row }: any) => {
        const lastSignal = row.original.lastSignal || "";
        const lastSignalDate = lastSignal.split("T")[0]; // TODO: this is wildly used across the code, needs a common utility for formatting dates
        const formattedLastSignal = lastSignalDate
          ? timeAgo.format(new Date(lastSignalDate), "round")
          : "No Signal";
        const personName = row.original.name || "";
        return (
          <Box
            onClick={(e) => onLastSignalClicked(e, personName)}
            sx={{
              display: "flex",
              alignItems: "center",
              textTransform: "capitalize",
              width: "120px",
              cursor: "pointer",
            }}
          >
            <Tooltip title={lastSignalDate} placement="top">
              <Box>{formattedLastSignal}</Box>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      Header: () => <Head>Title</Head>,
      Footer: "title",
      accessor: "title",
      placeholder: "Search by title",
      className: "cell-left collapse-column",
      disableGroupBy: true,
      Filter: TextArea,
      borderLeft: "1px solid #E5E5E5",
      filter: textIncludes,
      style: {
        width: "230px",
        minWidth: "230px",
        maxWidth: "230px",
      },
      Cell: ({ row }: any) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textTransform: "capitalize",
            width: "200px",
          }}
        >
          {row.original.title || "N/A"}
        </Box>
      ),
    },
    {
      Header: () => <Head>Engagement</Head>,
      Footer: "Engagement",
      accessor: "engagement",
      placeholder: "Search by engagement",
      className: "cell-left",
      style: {
        width: "200px",
        minWidth: "200px",
        maxWidth: "200px",
      },
      disableGroupBy: true,
      borderLeft: "1px solid #E5E5E5",
      Filter: TextArea,
      filter: (rows: any, id: any, filterValue: any) => rows.filter((row: any) => {
        const rowValue = row.values[id];
        if (!Array.isArray(rowValue)) return false;
        const formattedEngagement = rowValue
          .map((category: ActivityCategory) => ActivityCategory[category])
          .filter(Boolean);

        const engagementString = formattedEngagement.length > 0 ? formattedEngagement.join(", ") : "N/A";
        return String(engagementString).toLowerCase().includes(filterValue.toLowerCase());
      }),
      Cell: ({ row }: any) => {
        const engagement = (row.original.engagement || []) as ActivityCategory[];
        const formattedEngagement = engagement
          .map((category: ActivityCategory) => ActivityCategory[category])
          .filter(Boolean);

        const engagementString = engagement.length > 0 ? formattedEngagement.join(", ") : "N/A";
        return (
          <Box
            sx={{
              height: "54px !important",
              display: "flex",
              alignItems: "center",
              textTransform: "capitalize",
              width: "200px",
            }}
          >
            {engagementString || "N/A"}
          </Box>
        );
      },
    },
    {
      Header: () => <Head>Links</Head>,
      Footer: "links",
      accessor: "links",
      disableGroupBy: true,
      borderLeft: "1px solid #E5E5E5",
      style: {
        width: "130px",
        minWidth: "130px",
        maxWidth: "130px",
      },
      Cell: ({ row }: any) => {
        const onOpenLink = (type: 'tel' | 'link' | 'email', link: string) => () => {
          let fullLink: string
          switch (type) {
            case 'tel':
              fullLink = `tel:${link}`
              break;
            case 'email':
              fullLink = `mailto:${link}`
              break;
            case 'link':
              fullLink = link;
              break;
            default:
              return;
          }
          if (!fullLink) return
          onGoToExternalLink(fullLink)
        }
        return (
          <Box sx={{ width: "130px", display: 'flex', alignItems: 'center', gap: '10px', userSelect: 'none' }}>
            {row.original.phone && <PhoneInTalk style={{ height: '20px', cursor: 'pointer' }} onClick={onOpenLink('tel', row.original.phone)} />}
            {row.original.email && <EmailOutlined style={{ height: '20px', cursor: 'pointer' }} onClick={onOpenLink('email', row.original.email)} />}
            {row.original.salesForceLink && <SalesforceIcon style={{ height: '20px', cursor: 'pointer' }} onClick={onOpenLink('link', row.original.salesForceLink)} />}
          </Box>
        )
      }
    },
    {
      Header: () => <Head>Controls</Head>,
      Footer: "phone",
      accessor: "phone",
      className: "cell-left collapse-column",
      borderLeft: "1px solid #E5E5E5",
      style: {
        textAlign: 'center',
        width: "200px",
        minWidth: "200px",
        maxWidth: "200px",
      },
      Cell: ({ row }: any) => {
        const [isSaved, setIsSaved] = useState<boolean>(row.original.isSaved)
        const [isLoading, , asyncToggle] = useToggle()
        const onSave = async () => {
          const isSaved = await asyncToggle(onSaveContactToSalesforce(row.original.id))
          setIsSaved(isSaved)
        }

        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: 'center',
              alignItems: "center",
              textTransform: "capitalize",
              width: "200px",
            }}
          >
            {!isSaved ? (
              <Button
                onClick={onSave}
                size='extraSmall'
                variant="outlined"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px'
                }}
              >
                <span>
                  Save to Salesforce
                </span>
                {isLoading ? (
                  <CircularProgress size="12px" sx={{ color: "currentcolor" }} />
                ) : (
                  <SendOutlined style={{ transform: 'rotate(-45deg)' }} />
                )}
              </Button>
            ) : (
              <Stack direction='row' gap='5px' alignItems='center'>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#38cb82', color: 'white', padding: '1px', borderRadius: '50%' }}>
                  <BadgeCheckMark style={{ width: '15px', height: '15px', color: 'currentColor' }} />
                </Box>
                <span>Saved</span>
              </Stack>
            )}
          </Box>
        )
      },
    },
  ];

  const onClosePeopleFinderModal = () => {
    setShowFindPeopleModal(false)
    // update the list of people
    updatePeopleForAccount(currentAccountId!, { status: peopleStatus || "all" });
  }



  const haveSelection = selected.length > 0
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        border: "1px solid #eee",
        borderBottom: "2px solid #eee",
      }}
    >

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', p: '10px' }}>
        <Typography>
          Find new contacts to add to this account
        </Typography>
        {haveSelection ? (
          <Stack direction='row' gap='15px'>
            <Stack direction='row' alignItems='center' gap='10px'>
              <Typography>
                Save as
              </Typography>
              <Select value={contactType} sx={{ height: '36px', width: '110px' }} onChange={(event) => onContactSavedAsChange(event?.target?.value as ContactTypeEnum)}>
                <MenuItem value={ContactTypeEnum.Lead}>{ContactTypeEnum.Lead}s</MenuItem>
                <MenuItem value={ContactTypeEnum.Contact}>{ContactTypeEnum.Contact}s</MenuItem>
              </Select>
            </Stack>
            <Button variant="outlined" onClick={() => onSaveSelection()} sx={{ display: 'flex', gap: '10px' }}>
              <span>
                Save Selection to Salesforce
              </span>
              {isSavingContacts ? (
                <CircularProgress size="12px" sx={{ color: "currentcolor" }} />
              ) : (
                <SendOutlined style={{ transform: 'rotate(-45deg)' }} />
              )}
            </Button>
          </Stack>
        ) : (
          <Button variant="outlined" onClick={() => setShowFindPeopleModal(true)}>
            Find People
          </Button>
        )}
      </Box>
      <Box
        sx={{
          zIndex: 12,
          p: '10px',
          borderRadius: '15px',
          borderColor: 'red',
          m: '5px 0'
        }}
      >
        <FindAndSavePeopleModal
          isOpen={showFindPeopleModal}
          onClose={onClosePeopleFinderModal}
          account={account!}
        />
      </Box>
      <Box sx={{ zIndex: 1 }}>
        <Table
          sx={{ maxHeight: "450px" }}
          columns={productsColumns}
          data={peopleData}
          showFiltersRow //= {Boolean(peopleData.length)}
          customPlugins={[useFilters]}
          stickyHeader
          loading={isLoading}
          noDataMessage="There are no people associated with this account"
        />
      </Box>
    </Box>
  );
}

export default PeopleTab;
