import { useSearchQuery } from 'hooks';
import React, { useCallback, useEffect, useState } from 'react';
import aiWorkersService from 'services/aiworkers';

export const useWorkerPrePopulateParams = (cb: CallableFunction, id: string, deps?: any[]) => {
  const [params, setParams] = useState<any>();
  const [{ id: workerId }, updateSearchQuery] = useSearchQuery<{ id: string | null }>();
  const getWorkerParams = async (id: string) => {
    const { error, ...rest } = await aiWorkersService.workerCachedParams(id);
    if (error) {
      return;
    }
    updateSearchQuery({ id: null })
    setParams(rest)
    cb?.(rest)
  }

  useEffect(() => {
    id && getWorkerParams(id)
  }, [id, ...(Array.isArray(deps) ? deps : [])]);

  return params
}

interface UseClipboardStateProps {
  onPaste: (params: any) => void;
}

interface ClipboardData {
  text: string | null;
  error: string | null;
}

export const useClipboardState = ({ onPaste }: UseClipboardStateProps) => {
  const [clipboardData, setClipboardData] = useState<ClipboardData>({
    text: null,
    error: null,
  });
  // Function to read from the clipboard
  const readClipboard = useCallback(async () => {
    try {
      const text = await navigator.clipboard.readText();
      setClipboardData({ text, error: null });
    } catch (error) {
      setClipboardData({ text: null, error: 'Failed to read clipboard' });
    }
  }, []);

  // Function to write to the clipboard
  const writeClipboard = useCallback(async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setClipboardData({ text, error: null });
    } catch (error) {
      setClipboardData({ text: null, error: 'Failed to write to clipboard' });
    }
  }, []);

  // Handle the paste event
  const handlePaste = useCallback(
    (event: ClipboardEvent) => {
      const pasteText = event.clipboardData?.getData('text');
      if (pasteText) {
        onPaste(pasteText);
      }
    },
    [onPaste]
  );

  useEffect(() => {
    const handlePasteListener = (event: ClipboardEvent) => handlePaste(event);
    // Add paste event listener
    window.addEventListener('paste', handlePasteListener as EventListener);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('paste', handlePasteListener as EventListener);
    };
  }, [handlePaste]);

  return {
    clipboardData,
    readClipboard,
    writeClipboard,
  };
};