import { useSearchQuery } from "hooks";
import { useRef, useState } from "react";
import aiWorkersService from "services/aiworkers";
import { PowerRankAccount } from "types/api";

export const usePowerRankWorker = () => {
  const [result, setResult] = useState<PowerRankAccount[]>([]);
  const [remainingResults, setRemainingResults] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [{ id: cacheId }, updateSearchQuery] = useSearchQuery<{ id: string | undefined }>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const abortSignal = useRef(new AbortController());
  const rankAccounts = async ({ accounts }: { accounts: string[] }) => {
    try {
      abortSignal.current.abort();
      setLoading(true);
      setErrorMessage(null);
      abortSignal.current = new AbortController();
      const _accounts = accounts.filter(Boolean);
      const { results, remaining: remainingResults, resultsCacheId } = await aiWorkersService.powerRank({ accounts: _accounts, cacheId }, { signal: abortSignal.current.signal });
      if (!results?.length) {
        return setErrorMessage("No results found");
      }
      remainingResults && setRemainingResults(Math.abs(remainingResults))
      setResult(results);
    } catch (e: any) {
      setErrorMessage(e.response?.data?.message || "An error occurred while fetching data");
    } finally {
      setLoading(false);
    }
  };
  const resetErrorMessage = () => {
    setErrorMessage(null);
  };


  const onCancelRequest = () => {
    abortSignal.current.abort();
    setLoading(false);
    setErrorMessage(null);
  }

  const onResetResults = () => {
    updateSearchQuery({ id: undefined });
    setResult([]);
    setErrorMessage(null);
  }

  return {
    result,
    cacheId,
    remainingResults,
    loading,
    rankAccounts,
    errorMessage,
    resetErrorMessage,
    onCancelRequest,
    onResetResults
  };
};



