import {
  Button, CircularProgress, Tooltip, Typography,
} from "@mui/material";

import { Box } from "@mui/system";
import { useSearchQuery } from "hooks";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Account, AiWorker, WorkerPreCachedParams } from "types/api";

import { CustomTextField } from "../shared";
import AccountAutoCompleteField from "../shared/AccountAutoCompleteField";
import { useWorkerPrePopulateParams } from "../shared/hooks";
import TitlesListingWidget from "./TitleListingWidget";

interface PeopleRecommenderProps {
  onFind: (accounts: string[], jobs: string[]) => void;
  onResetErrorMessage: () => void;
  onCancelRequest: () => void;
  isLoading?: boolean;
  onResetForm: () => void;
  focusedAccount?: Account
}
const MAX_SELECTED_ACCOUNTS_LIMIT = 10;

function PeopleRecommender(props: PeopleRecommenderProps) {
  const { onFind, onResetErrorMessage, isLoading, onCancelRequest, focusedAccount } = props;
  const [accounts, setAccounts] = React.useState<string[]>([]);
  const [{ id: preStoredParamsId }] = useSearchQuery<{ id: string }>();

  const onUpdateAccounts = (values: string[]) => {
    if (values.length > MAX_SELECTED_ACCOUNTS_LIMIT) {
      toast.error(`You can only select up to ${MAX_SELECTED_ACCOUNTS_LIMIT} accounts`);
      return;
    }
    setAccounts(values);
    onResetErrorMessage();
  };



  const canSearchForPeople = accounts.length > 0 && !isLoading;
  const onFindPeople = () => {
    if (canSearchForPeople) {
      onFind(accounts, []);
    }
  };

  useWorkerPrePopulateParams((params: WorkerPreCachedParams) => {
    if (params.worker !== AiWorker.PERSON_FINDER) return;
    const { accountNames, jobTitles } = params.parameters;
    if (Array.isArray(accountNames) && accountNames.length) {
      setAccounts(accountNames)
    }
    onFind(accountNames, jobTitles);
  }, preStoredParamsId)

  const onResetForm = () => {
    setAccounts([]);
    props.onResetForm?.();
  }

  const [disableAccounts, setDisableAccounts] = useState(false);
  useEffect(() => {
    if (focusedAccount?.name) {
      setAccounts([focusedAccount.name])
      setDisableAccounts(true)
    }
  }, [JSON.stringify(focusedAccount)]);

  const shouldShowResetButton = !isLoading && Boolean(accounts.length);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <Typography sx={{ fontSize: "12px", color: "#333333" }}>
        Find People by Account Name, add the accounts you want contact recommendations for.
      </Typography>
      <div style={{ display: "flex", gap: "10px", alignItems: "start", flexWrap: 'wrap' }}>
        <AccountAutoCompleteField allowedSelectionNumber={1} values={accounts} placeholder="Account Name" onUpdate={onUpdateAccounts} disable={disableAccounts} />
        <Tooltip title={!canSearchForPeople ? "Please add at least one account and one job title" : ""} arrow>
          <div>
            <Button sx={{ height: "42px", gap: "5px" }} variant="contained" color="primary" disabled={!canSearchForPeople} onClick={onFindPeople}>
              Find People
              {isLoading && <CircularProgress size={12} color="inherit" sx={{ display: isLoading ? "block" : "none" }} />}
            </Button>
          </div>
        </Tooltip>
        {isLoading ? (
          <Button sx={{ height: "42px" }} variant="text" onClick={onCancelRequest}>Cancel</Button>
        ) : shouldShowResetButton && (
          <Button sx={{ height: "42px" }} variant="text" onClick={onResetForm}>Reset</Button>
        )}
      </div>
    </Box>
  );
}

export default PeopleRecommender;
