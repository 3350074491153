import axios from "axios";
import axiosRetry from "axios-retry";
import envConfig from "config/envConfig";
import { auth0ClientUser } from "components/ProtectedRoute";
import { captureExceptionWithLogRocket } from "./userActivitiesTracker/logRocket";
import { userStorage } from "./auth";

declare module "axios" {
  export interface AxiosRequestConfig {
    retryEnabled?: boolean;
  }
}


// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //

const AxiosNode = axios.create({
  baseURL: `${envConfig.nodeAPIDomain}/api`,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": window.location.origin,
  },
});
AxiosNode.interceptors.request.use(
  (config) => {
    const accessToken = userStorage.get("accessToken");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  async (error) => {
    return Promise.reject(error)
  },
);

AxiosNode.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    captureExceptionWithLogRocket(error)
    if (error.response) {
      if (error.response.status === 401) {
        userStorage.drop()
        auth0ClientUser.logout({ returnTo: window.location.origin });
      }
    } else {
      console.log('No response from server'); // This might indicate a network issue
    }

    return Promise.reject(error);
  },
);
axiosRetry(AxiosNode, {
  retries: 5,
  retryDelay: (retryCount) => 2 ** retryCount * 200,
  retryCondition: (error) => {
    const { config } = error;
    if (config && config.retryEnabled) {
      // retry on network errors or if the status >= 500
      return (
        axiosRetry.isNetworkOrIdempotentRequestError(error)
        // @ts-expect-error error might be undefined
        || error?.response.status >= 500
      );
    }
    return false;
  },
});


export { AxiosNode }

// fallback fetch util
export async function fetchWithAuth(url: string, token: string, data: object, options?: { signal?: any }) {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', // Indicates we're sending JSON data
        'Authorization': `Bearer ${token}`, // Adds the Authorization header
        'Access-Control-Allow-Origin': '*' // Enables CORS,
      },
      body: JSON.stringify(data), // Sends the data as JSON
      signal: options?.signal
    });

    if (response.status === 401) {
      userStorage.drop()
      auth0ClientUser.logout({ returnTo: window.location.origin });
    }

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response.json(); // Parses the response as JSON
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error; // Re-throw the error after logging it
  }
}