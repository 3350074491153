
const highQualityAccountsDummySet = [
  {
    "accountId": "0015G00001pC5hiQAC",
    "name": "St. Thomas University",
    "accountType": "Prospect",
    "annualRevenue": "10000000000",
    "numberOfEmployees": "200",
    "opportunityType": "Free Tier Customer",
    "opportunityDate": "2022-08-01"
  },
  {
    "accountId": "001f400001SbwNSAAZ",
    "name": "GEICO",
    "accountType": "Prospect",
    "annualRevenue": "38984000000",
    "numberOfEmployees": "28866",
    "opportunityType": "New Business - Production",
    "opportunityDate": "2021-02-10"
  },
  {
    "accountId": "001f400001NPgckAAD",
    "name": "C.H. Robinson",
    "accountType": "Customer",
    "annualRevenue": "17596000000",
    "numberOfEmployees": "20000",
    "opportunityType": "New Business",
    "opportunityDate": "2024-01-11"
  },
  {
    "accountId": "0015G00002AK2URQA1",
    "name": "Temasek",
    "accountType": "Prospect",
    "annualRevenue": "125987248000",
    "numberOfEmployees": "1000",
    "opportunityType": "Free Tier Customer",
    "opportunityDate": "2022-08-11"
  },
  {
    "accountId": "0015G00001vyuFZQAY",
    "name": "BlazeClan",
    "accountType": "Prospect",
    "annualRevenue": "18666000",
    "numberOfEmployees": "700",
    "opportunityType": "PLG",
    "opportunityDate": "2022-08-01"
  },
  {
    "accountId": "0015G00001mEGpEQAW",
    "name": "Drishti Banerjee",
    "accountType": "Prospect",
    "annualRevenue": null,
    "numberOfEmployees": null,
    "opportunityType": "Free Tier Customer",
    "opportunityDate": "2022-08-01"
  },
  {
    "accountId": "0015G00001nqHBXQA2",
    "name": "nameless",
    "accountType": "Prospect",
    "annualRevenue": "388342",
    "numberOfEmployees": "5",
    "opportunityType": "Free Tier Customer",
    "opportunityDate": "2022-08-01"
  },
  {
    "accountId": "0015G00002AK2USQA1",
    "name": "TD Bank",
    "accountType": "Prospect",
    "annualRevenue": null,
    "numberOfEmployees": null,
    "opportunityType": "Free Tier Customer",
    "opportunityDate": "2022-08-11"
  },
  {
    "accountId": "001f400000fyzXNAAY",
    "name": "Capgemini",
    "accountType": "Partner",
    "annualRevenue": "22522000000",
    "numberOfEmployees": "357000",
    "opportunityType": "Free Tier Customer",
    "opportunityDate": "2022-08-01"
  },
  {
    "accountId": "001Po000002Y6TGIA0",
    "name": "Leader Bank",
    "accountType": "Customer",
    "annualRevenue": "80000000",
    "numberOfEmployees": "420",
    "opportunityType": "Enterprise Plus",
    "opportunityDate": "2024-07-30"
  },
  {
    "accountId": "0015G00001vxk0bQAA",
    "name": "9 Ether Enterprises",
    "accountType": "Prospect",
    "annualRevenue": null,
    "numberOfEmployees": null,
    "opportunityType": "PLG",
    "opportunityDate": "2022-08-01"
  },
  {
    "accountId": "0015G00001pC5hiQAC",
    "name": "St. Thomas University",
    "accountType": "Prospect",
    "annualRevenue": "10000000000",
    "numberOfEmployees": "200",
    "opportunityType": "Free Tier Customer",
    "opportunityDate": "2022-08-01"
  },
  {
    "accountId": "001f400001SbwNSAAZ",
    "name": "GEICO",
    "accountType": "Prospect",
    "annualRevenue": "38984000000",
    "numberOfEmployees": "28866",
    "opportunityType": "New Business - Production",
    "opportunityDate": "2021-02-10"
  },
  {
    "accountId": "001f400001NPgckAAD",
    "name": "C.H. Robinson",
    "accountType": "Customer",
    "annualRevenue": "17596000000",
    "numberOfEmployees": "20000",
    "opportunityType": "New Business",
    "opportunityDate": "2024-01-11"
  },
  {
    "accountId": "0015G00002AK2URQA1",
    "name": "Temasek",
    "accountType": "Prospect",
    "annualRevenue": "125987248000",
    "numberOfEmployees": "1000",
    "opportunityType": "Free Tier Customer",
    "opportunityDate": "2022-08-11"
  },
  {
    "accountId": "0015G00001vyuFZQAY",
    "name": "BlazeClan",
    "accountType": "Prospect",
    "annualRevenue": "18666000",
    "numberOfEmployees": "700",
    "opportunityType": "PLG",
    "opportunityDate": "2022-08-01"
  },
  {
    "accountId": "0015G00001mEGpEQAW",
    "name": "Drishti Banerjee",
    "accountType": "Prospect",
    "annualRevenue": null,
    "numberOfEmployees": null,
    "opportunityType": "Free Tier Customer",
    "opportunityDate": "2022-08-01"
  },
  {
    "accountId": "0015G00001nqHBXQA2",
    "name": "nameless",
    "accountType": "Prospect",
    "annualRevenue": "388342",
    "numberOfEmployees": "5",
    "opportunityType": "Free Tier Customer",
    "opportunityDate": "2022-08-01"
  },
  {
    "accountId": "0015G00002AK2USQA1",
    "name": "TD Bank",
    "accountType": "Prospect",
    "annualRevenue": null,
    "numberOfEmployees": null,
    "opportunityType": "Free Tier Customer",
    "opportunityDate": "2022-08-11"
  },
  {
    "accountId": "001f400000fyzXNAAY",
    "name": "Capgemini",
    "accountType": "Partner",
    "annualRevenue": "22522000000",
    "numberOfEmployees": "357000",
    "opportunityType": "Free Tier Customer",
    "opportunityDate": "2022-08-01"
  },
  {
    "accountId": "001Po000002Y6TGIA0",
    "name": "Leader Bank",
    "accountType": "Customer",
    "annualRevenue": "80000000",
    "numberOfEmployees": "420",
    "opportunityType": "Enterprise Plus",
    "opportunityDate": "2024-07-30"
  },
  {
    "accountId": "0015G00001vxk0bQAA",
    "name": "9 Ether Enterprises",
    "accountType": "Prospect",
    "annualRevenue": null,
    "numberOfEmployees": null,
    "opportunityType": "PLG",
    "opportunityDate": "2022-08-01"
  }, {
    "accountId": "0015G00001pC5hiQAC",
    "name": "St. Thomas University",
    "accountType": "Prospect",
    "annualRevenue": "10000000000",
    "numberOfEmployees": "200",
    "opportunityType": "Free Tier Customer",
    "opportunityDate": "2022-08-01"
  },
  {
    "accountId": "001f400001SbwNSAAZ",
    "name": "GEICO",
    "accountType": "Prospect",
    "annualRevenue": "38984000000",
    "numberOfEmployees": "28866",
    "opportunityType": "New Business - Production",
    "opportunityDate": "2021-02-10"
  },
  {
    "accountId": "001f400001NPgckAAD",
    "name": "C.H. Robinson",
    "accountType": "Customer",
    "annualRevenue": "17596000000",
    "numberOfEmployees": "20000",
    "opportunityType": "New Business",
    "opportunityDate": "2024-01-11"
  },
  {
    "accountId": "0015G00002AK2URQA1",
    "name": "Temasek",
    "accountType": "Prospect",
    "annualRevenue": "125987248000",
    "numberOfEmployees": "1000",
    "opportunityType": "Free Tier Customer",
    "opportunityDate": "2022-08-11"
  },
  {
    "accountId": "0015G00001vyuFZQAY",
    "name": "BlazeClan",
    "accountType": "Prospect",
    "annualRevenue": "18666000",
    "numberOfEmployees": "700",
    "opportunityType": "PLG",
    "opportunityDate": "2022-08-01"
  },
  {
    "accountId": "0015G00001mEGpEQAW",
    "name": "Drishti Banerjee",
    "accountType": "Prospect",
    "annualRevenue": null,
    "numberOfEmployees": null,
    "opportunityType": "Free Tier Customer",
    "opportunityDate": "2022-08-01"
  },
  {
    "accountId": "0015G00001nqHBXQA2",
    "name": "nameless",
    "accountType": "Prospect",
    "annualRevenue": "388342",
    "numberOfEmployees": "5",
    "opportunityType": "Free Tier Customer",
    "opportunityDate": "2022-08-01"
  },
  {
    "accountId": "0015G00002AK2USQA1",
    "name": "TD Bank",
    "accountType": "Prospect",
    "annualRevenue": null,
    "numberOfEmployees": null,
    "opportunityType": "Free Tier Customer",
    "opportunityDate": "2022-08-11"
  },
  {
    "accountId": "001f400000fyzXNAAY",
    "name": "Capgemini",
    "accountType": "Partner",
    "annualRevenue": "22522000000",
    "numberOfEmployees": "357000",
    "opportunityType": "Free Tier Customer",
    "opportunityDate": "2022-08-01"
  },
  {
    "accountId": "001Po000002Y6TGIA0",
    "name": "Leader Bank",
    "accountType": "Customer",
    "annualRevenue": "80000000",
    "numberOfEmployees": "420",
    "opportunityType": "Enterprise Plus",
    "opportunityDate": "2024-07-30"
  },
  {
    "accountId": "0015G00001vxk0bQAA",
    "name": "9 Ether Enterprises",
    "accountType": "Prospect",
    "annualRevenue": null,
    "numberOfEmployees": null,
    "opportunityType": "PLG",
    "opportunityDate": "2022-08-01"
  }, {
    "accountId": "0015G00001pC5hiQAC",
    "name": "St. Thomas University",
    "accountType": "Prospect",
    "annualRevenue": "10000000000",
    "numberOfEmployees": "200",
    "opportunityType": "Free Tier Customer",
    "opportunityDate": "2022-08-01"
  },
  {
    "accountId": "001f400001SbwNSAAZ",
    "name": "GEICO",
    "accountType": "Prospect",
    "annualRevenue": "38984000000",
    "numberOfEmployees": "28866",
    "opportunityType": "New Business - Production",
    "opportunityDate": "2021-02-10"
  },
  {
    "accountId": "001f400001NPgckAAD",
    "name": "C.H. Robinson",
    "accountType": "Customer",
    "annualRevenue": "17596000000",
    "numberOfEmployees": "20000",
    "opportunityType": "New Business",
    "opportunityDate": "2024-01-11"
  },
  {
    "accountId": "0015G00002AK2URQA1",
    "name": "Temasek",
    "accountType": "Prospect",
    "annualRevenue": "125987248000",
    "numberOfEmployees": "1000",
    "opportunityType": "Free Tier Customer",
    "opportunityDate": "2022-08-11"
  },
  {
    "accountId": "0015G00001vyuFZQAY",
    "name": "BlazeClan",
    "accountType": "Prospect",
    "annualRevenue": "18666000",
    "numberOfEmployees": "700",
    "opportunityType": "PLG",
    "opportunityDate": "2022-08-01"
  },
  {
    "accountId": "0015G00001mEGpEQAW",
    "name": "Drishti Banerjee",
    "accountType": "Prospect",
    "annualRevenue": null,
    "numberOfEmployees": null,
    "opportunityType": "Free Tier Customer",
    "opportunityDate": "2022-08-01"
  },
  {
    "accountId": "0015G00001nqHBXQA2",
    "name": "nameless",
    "accountType": "Prospect",
    "annualRevenue": "388342",
    "numberOfEmployees": "5",
    "opportunityType": "Free Tier Customer",
    "opportunityDate": "2022-08-01"
  },
  {
    "accountId": "0015G00002AK2USQA1",
    "name": "TD Bank",
    "accountType": "Prospect",
    "annualRevenue": null,
    "numberOfEmployees": null,
    "opportunityType": "Free Tier Customer",
    "opportunityDate": "2022-08-11"
  },
  {
    "accountId": "001f400000fyzXNAAY",
    "name": "Capgemini",
    "accountType": "Partner",
    "annualRevenue": "22522000000",
    "numberOfEmployees": "357000",
    "opportunityType": "Free Tier Customer",
    "opportunityDate": "2022-08-01"
  },
  {
    "accountId": "001Po000002Y6TGIA0",
    "name": "Leader Bank",
    "accountType": "Customer",
    "annualRevenue": "80000000",
    "numberOfEmployees": "420",
    "opportunityType": "Enterprise Plus",
    "opportunityDate": "2024-07-30"
  },
  {
    "accountId": "0015G00001vxk0bQAA",
    "name": "9 Ether Enterprises",
    "accountType": "Prospect",
    "annualRevenue": null,
    "numberOfEmployees": null,
    "opportunityType": "PLG",
    "opportunityDate": "2022-08-01"
  }
];

type FilterRange = {
  min?: number;
  max?: number;
};

type Filters = {
  accountType: string[];
  opportunityType: string[];
  dealPeriod: FilterRange;
  dealSize: FilterRange;
  companySize: FilterRange;
};

type DataItem = {
  accountType: string;
  opportunityType: string;
  dealPeriod: number;
  dealSize: number;
  companySize: number;
};

type Metadata = {
  accountTypes: string[];
  opportunityTypes: string[];
  dealPeriods?: FilterRange;
  dealSizes?: FilterRange;
  companySizes?: FilterRange;
};

type FilterResult = {
  data: DataItem[];
  metadata: Metadata;
};

export function filterHQAsData(
  data: DataItem[],
  filters: Filters,
  limit: number = data.length,
  offset: number = 0
): FilterResult {
  // Destructure filters
  const { accountType, opportunityType, dealPeriod, dealSize, companySize } = filters;


  // Apply filters to data
  const filteredData = data.filter((item) => {
    const hasFilters = accountType.length || opportunityType.length;
    // Account Type filter
    if (accountType.length) {
      return accountType.includes(item.accountType)
    }

    // Opportunity Type filter
    if (opportunityType.length) {
      return opportunityType.includes(item.opportunityType);
    }

    // // Deal Period filter
    // if (dealPeriod.min !== undefined && item.dealPeriod < dealPeriod.min) {
    //   return false;
    // }
    // if (dealPeriod.max !== undefined && item.dealPeriod > dealPeriod.max) {
    //   return false;
    // }

    // // Deal Size filter
    // if (dealSize.min !== undefined && item.dealSize < dealSize.min) {
    //   return false;
    // }
    // if (dealSize.max !== undefined && item.dealSize > dealSize.max) {
    //   return false;
    // }

    // // Company Size filter
    // if (companySize.min !== undefined && item.companySize < companySize.min) {
    //   return false;
    // }
    // if (companySize.max !== undefined && item.companySize > companySize.max) {
    //   return false;
    // }

    return hasFilters ? false : true;
  });

  // Pagination (limit and offset)
  const paginatedData = filteredData.slice(offset, offset + limit);

  // Generate metadata
  const metadata: Metadata = {
    accountTypes: [...new Set(data.map((item) => item.accountType))],
    opportunityTypes: [...new Set(data.map((item) => item.opportunityType))],
  };
  return {
    data: paginatedData,
    metadata,
  };
}


export const filterHQAs = async (filters: Filters, limit: number = 10, offset: number = 0) => {
  return filterHQAsData(highQualityAccountsDummySet as any as DataItem[], filters, limit, offset);
}
export default highQualityAccountsDummySet;