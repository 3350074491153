import { Box } from "@mui/material";
import { useMemo } from "react";

import { Table } from "components/common/Tables";
import { makeTerritoryData } from "data/territoryTable";
import useTerritoryFilters from "services/shooks/territoryFilters";

import { DynFilterColumn } from "types/api";

import { useTerritoryColumns } from "./hooks";
import { TerritoryDisplayMode } from "pages/Territory";

interface ITerritoryTableProps {
  tableMaxWidth?: number;
  visibleColumnsWidth?: number;
  columnsMeta?: DynFilterColumn;
  tableContainerHeight: number;
  displayMode?: TerritoryDisplayMode
}

export function TerritoryTable({
  tableMaxWidth,
  visibleColumnsWidth,
  columnsMeta = {},
  tableContainerHeight,
  displayMode = TerritoryDisplayMode.Pages
}: ITerritoryTableProps) {
  const { state, isLoading = true } = useTerritoryFilters();
  const { columns } = useTerritoryColumns({
    tableMaxWidth, visibleColumnsWidth, columnsMeta,
  });
  const { accounts: accountList } = state;

  const accountsTableData = useMemo(
    () => (makeTerritoryData(accountList, state.selectionState)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(accountList), JSON.stringify(state.selectionState)],
  );

  return (
    <Box
      flex={1}
      sx={{
        overflow: displayMode === TerritoryDisplayMode.Pages ? "hidden" : 'auto',
        boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.14)",
        width: `${tableMaxWidth}px`,
      }}
    >
      <Table
        data={accountsTableData as any}
        columns={columns}
        loading={isLoading}
        stickyHeader
        sx={{
          overflow: "auto",
          background: "#fff",
          minHeight: "100%",
          maxHeight: tableContainerHeight
        }}
        memoDeps={[JSON.stringify(state.selectionState), accountList]}
        loadingSkeletonOptions={{
          height: "56px",
          rowsCount: state.pagination.pageSize,
        }}
        cellSX={{ height: "76px !important" }}
        dynColsDetails={columnsMeta}
      />
    </Box>
  );
}

export default TerritoryTable;
