import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  SxProps,
  Table,
  TableContainer,
  TablePagination,
} from "@mui/material";
import { Ref, forwardRef, useMemo, useState } from "react";
import {
  Column,
  TableState,
  useColumnOrder,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useTable,
  UsePaginationOptions,
} from "react-table";

import { DynFilterColumn } from "types/api";

import { TableBody, TableHead } from "./common";

interface DefaultTableProps {
  data: TableState[];
  columns: Column<any>[];
  loading?: boolean;
  initialState?: Partial<TableState<object>>;
  stickyHeader?: boolean;
  updatePaginationProps?: any;
  showFiltersRow?: boolean;
  onRowClick?: (row: any) => void;
  sx?: SxProps;
  cellSX?: SxProps;
  headerBackground?: string;
  customPlugins?: any[];
  noDataMessage?: string;
  memoDeps?: any;
  tbodySX?: SxProps;
  hasPagination?: boolean;
  pageSize?: number;
  loadingSkeletonOptions?: {
    rowsCount?: number;
    height?: number | string;
  };
  dynColsDetails?: DynFilterColumn;
}

const DefaultTable = forwardRef(
  (props: DefaultTableProps, ref: Ref<HTMLDivElement>) => {
    const {
      data,
      columns,
      loading,
      pageSize,
      hasPagination,
      initialState,
      sx,
      noDataMessage,
      onRowClick,
      customPlugins,
      dynColsDetails,
    } = props;
    const {
      stickyHeader = true,
      loadingSkeletonOptions,
      showFiltersRow,
      headerBackground,
      tbodySX,
      cellSX,
    } = props;

    const tableOptions = { columns, data, initialState };
    const tablePlugins = [
      useColumnOrder,
      useGlobalFilter,
      ...(customPlugins || []),
      useSortBy,
    ];

    if (hasPagination) {
      tablePlugins.push(usePagination);
    }

    const {
      prepareRow,
      rows,
      getTableProps,
      getTableBodyProps,
      headerGroups,
      ...resetProps
    } = useTable(tableOptions, ...tablePlugins);

    const tableBodyProps = getTableBodyProps();
    const tableProps = useMemo(() => getTableProps(), [getTableProps]);

    // Handle page change
    const handlePageChange = (event: unknown, newPage: number) => {
      // @ts-ignore
      resetProps?.gotoPage?.(newPage);
    };

    return (
      <TableContainer sx={sx} ref={ref}>
        <Table {...tableProps} stickyHeader={stickyHeader}>
          <TableHead
            headerGroups={headerGroups}
            showFiltersRow={showFiltersRow}
            backgroundColor={headerBackground}
            cellSX={cellSX}
            dynColsDetails={dynColsDetails}
          />
          <TableBody // @ts-ignore
            rows={hasPagination ? resetProps.page : rows}
            prepareRow={prepareRow}
            tableBodyProps={tableBodyProps}
            loading={Boolean(loading)}
            onRowClick={onRowClick}
            headerGroups={headerGroups}
            showFiltersRow={showFiltersRow}
            noDataMessage={noDataMessage}
            loadingSkeletonOptions={loadingSkeletonOptions}
            sx={tbodySX}
            cellSX={cellSX}
          />
        </Table>
        {hasPagination && (pageSize ?? 0) < rows.length && (
          <Box
            sx={{
              display: "flex",
              m: "20px 0px",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {/* Pagination */}
            <Pagination
              sx={{
                "& .Mui-selected": {
                  bgcolor: "#1890ffe6 !Important",
                  color: "white",
                  fontWeight: "bold",
                },
              }}
              // @ts-ignore
              count={Math.floor(rows.length / pageSize)} // @ts-ignore
              pageSize={pageSize}
              variant="outlined" // @ts-ignore
              page={resetProps?.state?.pageIndex ?? 1} // Pagination component is 1-based
              onChange={handlePageChange} // @ts-ignore
              showFirstButton={resetProps.showFirstButton} // @ts-ignore
              showLastButton={resetProps.showLastButton}
              siblingCount={0} // Adjust as needed for how many page numbers to display around the current page
            />
          </Box>
        )}
      </TableContainer>
    );
  }
);

export default DefaultTable;
