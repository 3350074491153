import { Auth0Provider, ICache } from "@auth0/auth0-react";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

import config from "config/envConfig";
import { userStorage } from "utils/auth";

const { authDomain, authClientId } = config;
const sessionStorageCache = {
  get(key) {
    const session = sessionStorage.getItem(key);
    return session ? JSON.parse(session) : {};
  },
  set(key, value: any) {
    if (value?.body?.id_token) {
      userStorage.set("accessToken", value?.body?.id_token);
    }
    sessionStorage.setItem(key, JSON.stringify(value));
  },
  remove(key) {
    sessionStorage.removeItem(key);
    // Storage.removeItem('accessToken');
  },
  // Optional
  allKeys() {
    return Object.keys(sessionStorage);
  },
} as ICache;
function AuthProvider({ children }: { children: ReactNode | string }) {
  const navigate = useNavigate();
  const redirectURI = window.location.origin;

  const onRedirectCallback = (appState: any) => {
    console.log("appState", appState);
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={authDomain!}
      clientId={authClientId!}
      redirectUri={redirectURI}
      onRedirectCallback={onRedirectCallback}
      skipRedirectCallback={window.location.pathname?.includes("settings/integrations")}
      // connection="google-oauth2"
      // connection="salesforce"
      maxAge={86400}
      // this should act as similarly as the prebuilt prop  
      // cacheLocation="localstorage"
      cache={sessionStorageCache}
    >
      {children}
    </Auth0Provider>
  );
}

export default AuthProvider;
