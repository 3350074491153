import { DownloadOutlined } from '@ant-design/icons';
import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { Formatter } from 'utils/file';


interface DownloadResultsProps {
  totalCount: number;
  hasRemaining: boolean;
  messages: {
    noResults: string,
    allResultsMessage: string,
    remainingMessage: string
  },
  onDownload: () => void
}


const DownloadResults = (props: DownloadResultsProps) => {
  const { totalCount, onDownload, messages, hasRemaining } = props;

  return (
    <Stack
      sx={{
        padding: "20px",
        backgroundColor: "#ffffff",
        borderRadius: '10px',
        border: "1px solid #e0e0e0",
      }}
    >
      <Stack direction={'row'} alignItems='center' justifyContent='space-between'>
        <Typography variant='body1'>
          {totalCount > 0 ? hasRemaining ?
            messages.remainingMessage
            : messages.allResultsMessage
            : messages.noResults}
        </Typography>
        <Button
          onClick={onDownload}
          variant='outlined'
          sx={{
            display: 'flex',
            gap: '10px'
          }}
        >
          <span>Download</span>
          <DownloadOutlined style={{ fontSize: '17px' }} />
        </Button>
      </Stack>
    </Stack>
  )
}

export default DownloadResults