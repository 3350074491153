import featureFlagsConfig from "./featureFlagsConfig";

interface EnvConfig {
  authClientId: string | undefined;
  authDomain: string | undefined;
  nodeAPIDomain: string | undefined;
  featureFlagsConfig: {
    [key: string]: boolean;
  };
  logRocketId: string | undefined;
  isDevEnvironment: boolean;
  apiVersion: string | undefined;
  RevicAPIKey: string | undefined;
}
const isDevEnvironment = window.location.hostname === "localhost" || window.location.hostname === "staging.salesdash.ai";

const envConfig: EnvConfig = {
  authClientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  authDomain: process.env.REACT_APP_AUTH0_DOMAIN,
  nodeAPIDomain: process.env.REACT_APP_SALESDASH_NODE_API_DOMAIN,
  featureFlagsConfig,
  logRocketId: process.env.REACT_APP_LOG_ROCKET_ID,
  isDevEnvironment,
  apiVersion: process.env.REACT_APP_SALESDASH_API_VERSION,
  RevicAPIKey: process.env.REACT_APP_REVIC_API_KEY,
};

export default envConfig;
